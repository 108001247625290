@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
a {
    text-decoration: none; /* no underline */
  }
.app {
  width: 100%;
  font-family: "Poppins", sans-serif;
}
.header {
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}
.header_container{
    width: 85%;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
}
.header_logo{
    width: 300px;
    height: 115px;
    background-image: url(./images/logo.png);
    background-size: 99%;
    background-position: center;
    background-repeat: no-repeat;
}
.header_navbar{
    position: absolute;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 21px;
    color: #898c95;
    font-family: "Poppins", sans-serif;
}
.header_navbar_item{
    margin-left: 50px;
    font-weight: 700;
    cursor: pointer;
    transition: 0.3s;
}
.header_navbar_item:hover{
    color: #66c0eb;
    transition: 0.3s;
}
.mainpage{
    width: 85%;
    margin: auto;
    display: flex;
    align-items: center;
    margin-top: 200px;
    position: relative;
}
.mainpage_left{

}
.mainpage_right{
   width: 900px;
   height: 800px;
   position: absolute;
   right: 0;
   background-image: url(./images/placeholder.png);
   background-repeat: no-repeat;
   background-position: center;
   background-size: 99%;
   margin-top: -100px;
}
.mainpage_left_header_top{
    font-size: 80px;
    color: black;
    font-family: "Poppins", sans-serif;
    font-weight: 800;
}
.mainpage_left_header_middle{
    font-size: 55px;
    color: black;
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    margin-top: -15px;
}
.mainpage_left_header_desc{
    max-width: 650px;
    font-size: 21px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    margin-top: 20px;
}
.mainpage_left_socials{
    margin-top: 40px;
    display: flex;
    align-items: center;
}
.mainpage_left_socials_facebookbtn{
    width: 260px;
    height: 75px;
    background-color: #212832;
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    zoom: 90%;
    transition: 0.3s;
}
.mainpage_left_socials_facebookbtn:hover{
    background-color: #66c0eb;
    transition: 0.3s;
}
.mainpage_left_socials_cont{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 25px;
}
.mainpage_left_socials_facebookbtn_icon{
    width: 40px;
    height: 40px;
    background-image: url(./images/fb.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50%;
}

.mainpage_left_socials_facebookbtn_texts{
    margin-left: 20px;
}
.mainpage_left_socials_facebookbtn_head{
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    color: white;
}
.mainpage_left_socials_facebookbtn_desc{
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    color: white;
}
.mainpage_left_socials_fb{
    width: 40px;
    height: 40px;
    background-image: url(./images/fbbig.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 90%;
    margin-left: 10px;
    cursor: pointer;
    transition: 0.3s;
}
.mainpage_left_socials_fb:hover{
    background-image: url(./images/fbbig_hover.png);
    transition: 0.3s;
}
.mainpage_left_socials_instagram{
    width: 40px;
    height: 40px;
    background-image: url(./images/instabig.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 90%;
    margin-left: 5px;
    cursor: pointer;
    transition: 0.3s;
}
.mainpage_left_socials_instagram:hover{
    background-image: url(./images/instabig_hover.png);
    transition: 0.3s;
}
.news{
    width: 95%;
    margin: auto;

    margin-top: 200px;
}
.news_cont{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.news_item{
    width: 780px;
    margin-right: 90px;
    margin-left: 90px;
    margin-bottom: 100px;
}
.news_item_image{
    object-fit: cover;
    width: 780px;
    height: 380px;
    background: #7e7b7b9e;
    border-radius: 15px 15px 0px 0px;
}
.news_item_infocont{
    margin-left: 60px;
    margin-top: 40px;
}
.news_item_title{
    font-size: 25px;
    color: #212832;
    font-weight: 600;
    text-transform: uppercase;
}
.news_item_desc{
    font-size: 19px;
    font-weight: 500;
    max-width: 550px;
    margin-top: 30px;
    color: #212832;
    word-wrap: break-word;
}
.news_item_buttoncont{
    display: flex;
    align-items: center;
    margin-top: 40px;
}
.news_item_button{
    width: 220px;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 21px;
    color: white;
    font-weight: 600;
    background-color: #66c0eb;
    border-radius: 15px;
    transition: 0.3s;
    cursor: pointer;
}
.news_item_button:hover{
    transition: 0.3s;
    -webkit-box-shadow:0px 0px 95px 9px rgba(102,193,235,1);
    -moz-box-shadow: 0px 0px 95px 9px rgba(102,193,235,1);
    box-shadow: 0px 0px 95px 9px rgba(102,193,235,1);
}
.news_item_date{
    font-weight: 500;
    font-size: 18px;
    color: #212832;
    margin-left: 40px;
}
.advertise_image{
    width: 85%;
    height: 350px;
    margin: auto;
    margin-top: 50px;
    border-radius: 15px;
    display: block;
    object-fit: cover;
    margin-bottom: 80px;
}
.footer{

}
.footer_title{
    color: #4a4f58;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}
.footer_codium{
    font-weight: 500;
    cursor: pointer;
    text-align: center;
    margin-bottom: 40px;
    margin-top: 10px;
    color: #4a4f58;
    display: block;
}
.mainpage_left_mobile_socials{
    display: flex;
    align-items: center;

}

::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
	background-color: #f5f5f500;
}

::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	background-image: -webkit-gradient(linear,
									   left bottom,
									   left top,
									   color-stop(0.44, rgb(122,153,217)),
									   color-stop(0.72, rgb(73,125,189)),
									   color-stop(0.86, rgb(28,58,148)));
}

@media only screen and (max-width: 3840px) {

	/* // 3840x2160 */
    .header{
        zoom: 200%;
    }
    .mainpage{
        zoom: 200%;
    }
    .news{
        zoom: 187%;       
    }
    .advertise_image{
        zoom: 200%;
    }
    .footer{
        zoom: 200%;       
    }

}

@media only screen and (max-width: 2560px) {

	/* // 2560x1440 , 2560x1600 */
    .header{
        zoom: 150%;
    }
    .mainpage{
        zoom: 150%;
    }
    .news{
        zoom: 126%;       
    }
    .advertise_image{
        zoom: 150%;
    }
    .footer{
        zoom: 150%;       
    }
}

@media only screen and (max-width: 2100px) {
    .header{
        zoom: 100%;
    }
    .mainpage{
        zoom: 100%;
    }
    .news{
        zoom: 94%;       
    }
    .advertise_image{
        zoom: 100%;
    }
    .footer{
        zoom: 100%;       
    }	
}

@media only screen and (max-width: 1920px) {

	/* // 1920x1080 */
    .header{
        zoom: 100%;
    }
    .mainpage{
        zoom: 100%;
    }
    .news{
        zoom: 94%;       
    }
    .advertise_image{
        zoom: 100%;
    }
    .footer{
        zoom: 100%;       
    }	
}

@media only screen and (max-width: 1600px) {

	/* //1600x900 , 1536x864 */
    .header{
        zoom: 80%;
    }
    .mainpage{
        zoom: 80%;
    }
    .news{
        zoom: 76%;       
    }
    .advertise_image{
        zoom: 80%;
    }
    .footer{
        zoom: 80%;       
    }	
}

@media only screen and (max-width: 1440px) {

	/* //1440x900 */
    .header{
        zoom: 70%;
    }
    .mainpage{
        zoom: 70%;
    }
    .news{
        zoom: 72%;       
    }
    .advertise_image{
        zoom: 80%;
    }
    .footer{
        zoom: 80%;       
    }	
}

@media only screen and (max-width: 1380px) {
/* 
	//1366x768 , 1280x720 */
    .header{
        zoom: 70%;
    }
    .mainpage{
        zoom: 70%;
    }
    .news{
        zoom: 62%;       
    }
    .advertise_image{
        zoom: 70%;
    }
    .footer{
        zoom: 70%;       
    }	
}

@media only screen and (max-width: 1024px) {

	/* //1024x768 */
    .header{
        zoom: 60%;
    }
    .mainpage{
        zoom: 60%;
    }
    .news{
        zoom: 50%;       
    }
    .advertise_image{
        zoom: 60%;
    }
    .footer{
        zoom: 60%;       
    }
}
@media only screen and (max-width:1024px) and (min-height: 800px) {

/*  //ipads */
.header{
    zoom: 60%;
}
.mainpage{
    zoom: 60%;
}
.news{
    zoom: 45%;       
}
.advertise_image{
    zoom: 60%;
}
.footer{
    zoom: 60%;       
}
}
@media only screen and (max-width: 854px) {

	/* //800x600 */
    .header{
        zoom: 45%;
    }
    .mainpage{
        zoom: 45%;
    }
    .news{
        zoom: 70%;       
    }
    .advertise_image{
        zoom: 50%;
    }
    .footer{
        zoom: 50%;       
    }
}

@media only screen and (max-width:540px) {
	
	/* //surface Duo */
    .header{
        zoom: 50%;
    }
    .mainpage{
        zoom: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 100px;
    }
    .mainpage_right{
        position: inherit;
        margin-top: 50px;

    }
    .mainpage_left_mobile_socials{
        margin: auto;
    }
    .mainpage_left_header_top{
        text-align: center;
    }
    .mainpage_left_header_middle{
        text-align: center;       
    }
    .mainpage_left_header_desc{
        text-align: center;       
    }
    .news{
        zoom: 55%;       
    }
    .advertise_image{
        zoom: 50%;
    }
    .footer{
        zoom: 50%;       
    }
}

@media only screen and (max-width:480px) {
    .header{
        zoom: 45%;
    }
    .mainpage{
        zoom: 45%;

    }
    .news{
        zoom: 60%;       
    }
    .advertise_image{
        zoom: 45%;
    }
    .footer{
        zoom: 45%;       
    }
}
@media only screen and (max-width:431px) {
    .header{
        zoom: 40%;
    }
    .mainpage{
        zoom: 40%;

    }
    .news{
        zoom: 45%;       
    }
    .advertise_image{
        zoom: 40%;
    }
    .footer{
        zoom: 40%;       
    }
}

@media only screen and (max-width:380px) {
    .header{
        zoom: 40%;
    }
    .mainpage{
        zoom: 40%;

    }
    .news{
        zoom: 40%;       
    }
    .advertise_image{
        zoom: 40%;
    }
    .footer{
        zoom: 40%;       
    }	
}

@media only screen and (max-width:400px) {
	
}


@media only screen and (max-width:380px) {
	
}

@media only screen and (max-width:340px) {
	
}

@media only screen and (max-width:300px) {
	
}
@supports (-webkit-touch-callout: none) {
     /* //iphone only */

	
}
  
